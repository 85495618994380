<template>
   <div class="vs-row">
        <template lang="html">
          <div>
            <vx-card actionable class="cardx" title="STATS">
              <vs-row style="margin-bottom: 1%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="9">
                  <vs-select
                    class="selectExample"
                    label="Cities"
                    v-model="selectedcity"
                    >
                    <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in cities" />
                  </vs-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                  <vs-select
                    class="selectExample"
                    label="Person"
                    v-model="selectedPerson"
                    >
                    <vs-select-item :key="index" :value="item" :text="generatename(item)" v-for="(item,index) in Person" />
                  </vs-select>
                </vs-col>
              </vs-row>
              <vs-table  max-items="10" :data="users">
                  <template slot="thead" class="exc">
                      <vs-th >
                      Level
                      </vs-th>
                      <vs-th>
                      Database
                      </vs-th>
                      <vs-th>
                      Net Enquiry
                      </vs-th>
                      <vs-th>
                      Visitors
                      </vs-th>
                      <vs-th>
                      Eligibility
                      </vs-th>
                      <vs-th>
                      Loan
                      </vs-th>
                      <vs-th>
                      FLAGGING DELAYS(%)
                      </vs-th>
                      <vs-th>
                      JOB FOR TODAY
                      </vs-th>
                      <vs-th>
                      I'M WAITING(%)
                      </vs-th>
                  </template>

                  <template slot-scope="{data}">
                      <vs-tr :style="{backgroundColor: (data[indextr].level=='M6'||data[indextr].level=='M5'||data[indextr].level=='M3+'||data[indextr].level=='M3'||data[indextr].level=='M3++'? 'chartreuse' : 'transparent' && data[indextr].level=='M4'||data[indextr].level=='M2'||data[indextr].level=='N/A'||data[indextr].level=='OS'? '#d89177' : 'transparent'
                      && data[indextr].level=='L5'||data[indextr].level=='L4'||data[indextr].level=='L3+'||data[indextr].level=='L3'||data[indextr].level=='L2'? '#25d425' : 'transparent'&& data[indextr].level=='L4-'||data[indextr].level=='L2-'? '#f7ac039c' : 'transparent'&& data[indextr].level=='Total'? '#79e8e3' : 'transparent'
                       && data[indextr].level=='#N/A'||data[indextr].level=='BLANKS'? '#ffbc00' : 'transparent')}" :key="indextr" v-for="(tr, indextr) in data" >
                      <vs-td  :data="data[indextr].level" >
                          {{data[indextr].level}}
                      </vs-td>

                       <vs-td :data="data[indextr].database">
                         <div  @click="ByCity('database')">
                          {{data[indextr].database}}
                        </div>
                      </vs-td>

                       <vs-td :data="data[indextr].net_enquiry">
                         <div @click="ByCity('net_enquiry')">
                           {{getWholePercent(data[indextr].net_enquiry , data[indextr].database, indextr, 'net_enquiry')}}
                         </div>
                      </vs-td>

                      <vs-td :data="data[indextr].visitors">
                          <div @click="ByCity('visitors')">
                           {{getWholePercent(data[indextr].visitors , data[indextr].database , indextr, 'visitors')}}
                         </div>
                      </vs-td>

                      <vs-td :data="data[indextr].eligibility">
                          <div @click="ByCity('eligibility')">
                           {{getWholePercent(data[indextr].eligibility , data[indextr].database , indextr, 'eligibility')}}
                         </div>
                      </vs-td>

                      <vs-td :data="data[indextr].loan">
                          <div @click="ByCity('loan')">
                           {{getWholePercent(data[indextr].loan , data[indextr].database , indextr, 'loan')}}
                         </div>
                      </vs-td>

                      <vs-td :data="data[indextr].flagging_delays" :style="{backgroundColor: flagging_color(data[indextr].delay_percentage)}">
                          <div @click="ByCity('flagging_delays')">
                           {{getWholePercent(data[indextr].flagging_delays , data[indextr].database , indextr, 'flagging')}}
                         </div>
                      </vs-td>

                      <vs-td :data="data[indextr].job_for_today">
                          <div @click="ByCity('job_for_today')">
                           {{getWholePercent(data[indextr].job_for_today , data[indextr].database, indextr, 'job')}}
                         </div>
                      </vs-td>

                      <vs-td :data="data[indextr].iam_waiting">
                          <div @click="ByCity('iam_waiting')">
                           {{getWholePercent(data[indextr].iam_waiting , data[indextr].database, indextr, 'waiting')}}
                         </div>
                      </vs-td>

                      </vs-tr>
                  </template>
                </vs-table>  
            </vx-card>
          </div>  
        </template>   
             
    </div>
   

</template>
<script>
// import VueApexCharts from vue-apexcharts
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
export default {
    data:()=>({
    //   themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
    selectedcity: '',
    selectedPerson: '',
    users: [],
    usersDelhi:[
      {
        "level": "M6",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M5",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M4",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M3+",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M3",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M2",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L5",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L4",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L4-",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L3+",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L3",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L2",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L2-",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      }
    ],
    allUsers:[
      {
        "level": "M6",
        "database": 10,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"10",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M5",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M4",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M3+",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M3",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M2",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L5",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L4",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L4-",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L3+",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L3",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L2",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L2-",
        "database": 0,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "0",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      }
    ],
    usersMumbai:[
      {
        "level": "M6",
        "database": 19,
        "net_enquiry": "13",
        "visitors": "18",
        "eligibility": "4",
        "loan": "1",
        "flagging_delays":"18",
        "job_for_today": "1",
        "iam_waiting": "1",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M5",
        "database": 47 ,
        "net_enquiry": "31",
        "visitors": "42",
        "eligibility": "7",
        "loan": "0",
        "flagging_delays":"45",
        "job_for_today": "0",
        "iam_waiting": "2",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M4",
        "database": 17,
        "net_enquiry": "7",
        "visitors": "17",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"0",
        "job_for_today": "0",
        "iam_waiting": "17",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M3+",
        "database": 60,
        "net_enquiry": "55",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"58",
        "job_for_today": "0",
        "iam_waiting": "2",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M3",
        "database": 223,
        "net_enquiry": "108",
        "visitors": "2",
        "eligibility": "4",
        "loan": "0",
        "flagging_delays":"199",
        "job_for_today": "1",
        "iam_waiting": "23",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "M2",
        "database": 3,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "flagging_delays":"1",
        "job_for_today": "0",
        "iam_waiting": "2",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L5",
        "database": 165,
        "net_enquiry": "102",
        "visitors": "163",
        "eligibility": "25",
        "loan": "0",
        "flagging_delays":"71",
        "job_for_today": "0",
        "iam_waiting": "94",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L4",
        "database": 339,
        "net_enquiry": "180",
        "visitors": "370",
        "eligibility": "41",
        "loan": "2",
        "flagging_delays":"92",
        "job_for_today": "0",
        "iam_waiting": "247",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L4-",
        "database": 1410,
        "net_enquiry": "431",
        "visitors": "1391",
        "eligibility": "99",
        "loan": "0",
        "flagging_delays":"311",
        "job_for_today": "0",
        "iam_waiting": "1099",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L3+",
        "database": 119,
        "net_enquiry": "105",
        "visitors": "0",
        "eligibility": "6",
        "loan": "0",
        "flagging_delays":"55",
        "job_for_today": "0",
        "iam_waiting": "64",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L3",
        "database": 1121,
        "net_enquiry": "949",
        "visitors": "0",
        "eligibility": "29",
        "loan": "0",
        "flagging_delays":"643",
        "job_for_today": "2",
        "iam_waiting": "476",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L2",
        "database": 2180,
        "net_enquiry": "1045",
        "visitors": "3",
        "eligibility": "35",
        "loan": "0",
        "flagging_delays":"964",
        "job_for_today": "0",
        "iam_waiting": "1216",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      },
      {
        "level": "L2-",
        "database": 5307,
        "net_enquiry": "2780",
        "visitors": "1",
        "eligibility": "85",
        "loan": "0",
        "flagging_delays":"1323",
        "job_for_today": "0",
        "iam_waiting": "3984(75%)",
        "net_enquiry_percentage": 0,
        "visitors_percentage": 0,
        "eligibility_percentage": 0,
        "loan_percentage": 0,
        "delay_percentage": 0,
        "job_today_percentage": 0,
        "waiting_percentage": 0,
      }
    ],
    Person: [],
    Drilldown: '',
    cities: [ "All", "Hyderabad", "Delhi", "Mumbai", "Chennai", "Pune" ],
    PersonRaw: [
      {
        name: "John",
        city: "Delhi"
      },
      {
        name: "Tom",
        city: "Delhi"
      },
      {
        name: "Edward",
        city: "Delhi"
      },
      {
        name: "Krasinski",
        city: "Delhi"
      },
      {
        name: "Carter",
        city: "Hyderabad"
      },
      {
        name: "Bruce",
        city: "Hyderabad"
      },
      {
        name: "Lex Luther",
        city: "Hyderabad"
      },
      {
        name: "Peter",
        city: "Hyderabad"
      }
    ],
  }),
  mounted() {
    this.selectedcity = 'All'
  },
  watch: {
    selectedPerson: function(value) {
      this.selectedcity = value.city
    },
    selectedcity: function(value) {
      // console.log('selected city running')
      this.Person = []
      if (value === 'All') {
        this.PersonRaw.forEach((person) => {
          this.Person.push(person)
        })
      } else {
        this.PersonRaw.forEach((person) => {
          if (person.city === value) {
            this.Person.push(person)
          }
        })
      }
      switch(value) {
        case 'All':
          this.users = []
          this.allUsers.forEach((user) => {
            this.users.push(user)
          })
          break
        case 'Hyderabad':
          this.users = []
          this.usersMumbai.forEach((user) => {
            this.users.push(user)
          })
          break
        case 'Delhi':
          this.users = []
          this.usersDelhi.forEach((user) => {
            this.users.push(user)
          })
      }
      if (value !== 'All') {
        this.Drilldown = 'bde'
      } else {
        this.Drilldown = 'city'
      }
    }
  },
  components: {
        VueApexCharts,
        StatisticsCardLine,
        ChangeTimeDurationDropdown,
        'v-select': vSelect,
    },
  methods: {
    generatename(item) {
      let name = item.name + " - " + item.city
      return name
    },
    ByCity(category) {
      if (this.Drilldown === 'bde') {
        this.$store.commit('SET_STATS_BY_BDE_DETAILS', this.selectedcity)
        this.$router.push('/StatsByBde')
      } else if (this.Drilldown === 'city') {
        this.$store.commit('SET_STATS_BY_CITY_DETAILS', category)
        this.$router.push('/StatsByCity')
      }
    },
    getWholePercent(percentFor,percentOf, index, assignPlace) {
      percentFor = parseInt(percentFor)
      let finalPercentage = Math.floor(percentFor / percentOf * 100)
      this.assigning(index, assignPlace, finalPercentage)
      let string = percentFor + " | " + "(" + finalPercentage + "%)"

      return string
    },
    assigning(index, assignplace, value) {
      switch(assignplace) {
        case 'net_enquiry':
          this.users[index].net_enquiry_percentage = value
          break
        case 'visitors':
          this.users[index].visitors_percentage = value
          break
        case 'eligibility':
          this.users[index].eligibility_percentage = value
          break
        case 'loan':
          this.users[index].loan_percentage = value
          break
        case 'flagging':
          this.users[index].delay_percentage = value
          break
        case 'job':
          this.users[index].job_today_percentage = value
          break
        case 'waiting':
          this.users[index].waiting_percentage = value
          break
      }
    },
    flagging_color(flagging_delay) {
      if (flagging_delay > 50) {
        return 'red'
      } else {
        return 'transparent'
      }
    },
    waiting_color(waiting_delay) {
      // console.log(waiting_delay)
      if (waiting_delay > 50) {
        return 'red'
      } else {
        return 'tranparent'
      }
    }
  }
}
</script>
<style>
.vs-radar-text{
    color: darkgray;
    position: relative;
    bottom: 70px;
    left: 35px;
    font-size: larger;
}
.radial_quarter{
    position: relative;
    height: 260px;
}
.lineAreaChartSpline{
    position: relative;
    height: 260px;
}
.vs-radar-thisquarter{
    color: darkgray;
    position: relative;
    bottom: 70px;
    font-size: larger;
    text-align: center;
}
.input-search{
      position: relative;
    right: 10px;
}
.table_heading{
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr{
  /* background-color:#ffbc00; */
  font-family: sans-serif;
  font-size: smaller;
  /* border: 1px solid black; */
}
</style>

